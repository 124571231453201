import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar"
import Footer from "../components/footer"
import Contact from "../components/contact"
import "../components/singlepage.scss"

const WorkPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo
        lang="it"
        title="Stefano Maffina | Psicologo clinico | Gardone Val Trompia"
        description="Stefano Maffina, psicologo clinico a Gardone Val Trompia, BS"
      />
      <div className="fix-content-width">
        <NavBar />
        <div className="singlepage-container">
          <div className="section-subtitle singlepage-title">
            <h2
              data-aos="custom-fade"
              data-aos-duration="1000"
              style={{ color: "#121212" }}
            >
              <span style={{ fontWeight: 700, textDecoration: "italic" }}>
                Sostegno psicologico alla persona
              </span>
            </h2>
            <div
              data-aos="custom-border"
              data-aos-duration="1000"
              className="singlepage-title-border"
            ></div>
            <div className="fix-content-width why-content">
              <div style={{ marginTop: "64px", marginBottom: "64px" }}>
                <p>
                  Il sostegno psicologico è finalizzato al supporto della
                  persona in situazioni di vita specifiche.
                </p>
                <p>
                  Ognuno di noi può attraversare periodi particolarmente
                  stressanti, magari per stanchezza o perché più problemi si
                  presentano nello stesso momento. I motivi che spingono una
                  persona a chiedere aiuto ad uno psicologo sono diversi.
                </p>
                <p>
                  Di solito riguardano cambiamenti importanti della vita, i
                  quali possono essere difficili da affrontare da soli. In
                  questi casi ci si può sentire incapaci, la difficoltà può
                  apparire come uno scoglio insormontabile, e si rischia di
                  avvilirsi.
                </p>
                <p>
                  Questa situazione può mandarci in "tilt" e ci si può sentire
                  confusi senza riuscire a d uscire dallo stallo e a risolvere
                  il problema, ripercuotendosi negativamente in diversi ambiti
                  della vita e aumentando il malessere e i sentimenti di
                  inadeguatezza.
                </p>
                <p>
                  Il sostegno psicologico ha come finalità la gestione della
                  difficoltà, è teso a definire e inquadrare la problematica,
                  individuare e sviluppare le capacità di soluzione e le
                  strategie che la persona ha già in sé (risorse personali) ma
                  che non riesce a mettere in campo da solo perché schiacciato
                  dalla preoccupazione.
                </p>
                <p>
                  Il percorso è quindi teso a: uscire dal momento di crisi;
                  migliorare l'autostima e il senso di possibilità, attivare
                  soluzioni per modificare la situazione; accettare il
                  cambiamento per poter trovare un nuovo modo di vivere al
                  meglio la propria vita.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact />
      <Footer />
    </Layout>
  )
}

export default WorkPage
